import {  Action, createSlice } from "@reduxjs/toolkit";
import { ENGINE_URL, SQS_JOB } from "../CONSTANTS";
import axiosService from "../../support/http/AxiosService";
import { ThunkAction } from 'redux-thunk';

import { showToast } from "../../layout";
import { getTraiffs } from "../consumption/consumptionSlice";
import { getProjectStatus } from "../projectList/projectListSlice";

export const SolutionsInitialState = () => ({
  solutionStage: null,
  solutionAddersResponse: {},
  selectedSolution: {},
  solutionsList: [],
  solutionListLoader: false,
  designersList: [],
  roofTypeList: [],
  designRequirementData: {},
  designRequirementDataLoader: false,
  designerDropdownValues: {
    designer: null,
    panelBox: null,
  },
  consumptionAdjustments: {
    adjustmentType: "monthly_kwh_adjustment",
    adjustmentValue: null,
  },
  solarSystemAssumptions:{
    "solar_system_size": null,
    "target_yearly_production": null
},
solarSystemAssumptionsLoader:false,
  systemFinancialInfo: {},
  systemFinancialInfoLoader: false,
  postSolarTariff: [],
  postSolarTariffData: {},
  postSolarTariffDataLoader: false,
  postSolarTariffValues: {
    tariffId: null,
    rate: null,
  },
  panelGroupsData: {
    metadata: {},
    panel_groups: [],
  },
  updateBidsToggle: {
    bids_created: false,
    changeInAdjustmentOrPanelGroups: false,
    text: "",
  },
  panelGropuTableLoader: false,
  designConfigurationsLoader: false,
  solutionImages: [],
  solutionImagesLoader: false,
  batterySettings: {},
  batterySettingsLoader: false,
  addersLoader: false,
  solarAdders: {},
  imageGallery: {
    selectedBids: [],
    bidsFilters: {},
    bidListDataTotalCount: 0,
    bidsList: {},
  },
  bidsListLoader:false,
  selectedBidsListLoader:false,
  batteryBidsLoader:false,
  system_data: {},
  priorities: [],
  energyProfileFields: {
    avg_monthly_consumption: null,
    monthly_bill_adjustment: null,
    monthly_kwh_adjustment: null,
    target_bill_offset: null,
    avg_monthly_bill: null,
    target_monthly_production: null,
    target_yearly_production: null,
  },
  inflation_rate: null,

  designImages: [],
  adderAlertMessage: [],
  bidDetails: {},
  discountReasons: [],
  managerList: [],
  bidDetailFinancing: {},
  batteryList: {
    batteryMinCapacity: "",
    startTime: "",
    stateOfCharge: "",
    hourPowerOut: "",
    endTime: "",
    criticalLoad: "",
    dischargeMode: "",
  },
  loanOptions: {},
  cartItems: [],
  cartItemsLoader:false,
  rankedBatteryBids: [],
  rankedBatteryBidsLoader:false,
  customerSelectedBid: null,
  rankedSolarBids: [],
  userAgreement: {},
  templatesList: [],
  completedDesignsList: [],
  createSolutionErrorMessage: undefined,
  newSolutionFlag: 0,
  closeCreateSolutionPopup: "",
  generateContract: null,
  handleSolution: null,
  loansToogle: null,
  loan_inputs: {
    finance_type: "cash",
    financier_id: null,
    financier_loan_option_id: null,
    financier_loan_configuration_id: null,
    down_payment: null,
  },
  systemPanelGroupData: [],
  loansDropdownData: [],
  bidCalculationStatus: false,
  selectPriceRankbids: [],
  priceReductionData: {},
  changedSolutionToggle: false,
  projectSolutionStatus:{},
  actualProjectSolutionStatus:{},
});

export const solutionsSlice = createSlice({
  name: "solutions",
  initialState: SolutionsInitialState(),
  reducers: {
    setSolutionStage(state: any, action: any) {
      state.solutionStage = action.payload;
    },
    setSolutionsList(state: any, action: any) {
      state.solutionsList = action.payload;
    },
    setSolutionListLoader(state: any, action: any) {
      state.solutionListLoader = action.payload;
    },
    setSelectedSolution(state: any, action: any) {
      state.selectedSolution = action.payload;
    },
    setDesinersList(state: any, action: any) {
      state.designersList = action.payload;
    },
    setRoofTypeList(state: any, action: any) {
      state.roofTypeList = action.payload;
    },
    setDesinRequirementData(state: any, action: any) {
      state.designRequirementData = action.payload;
    },
    setDesinRequirementDataLoader(state: any, action: any) {
      state.designRequirementDataLoader = action.payload;
    },
    setDesignerDropdownValues(state: any, action: any) {
      state.designerDropdownValues = action.payload;
    },
    setSystemFinancialInfo(state: any, action: any) {
      state.systemFinancialInfo = action.payload;
    },
    setSystemFinancialInfoLoader(state: any, action: any) {
      state.systemFinancialInfoLoader = action.payload;
    },
    setConsumptionAdjustments(state: any, action: any) {
      state.consumptionAdjustments = action.payload;
    },
    setSolarSystemAssumptions(state : any, action : any){
      state.solarSystemAssumptions = action.payload
    },
    setSolarSystemAssumptionsLoader(state : any, action : any){
      state.solarSystemAssumptionsLoader = action.payload
    },
    setPostSolarTariff(state: any, action: any) {
      state.postSolarTariff = action.payload;
    },
    setPostSolarTariffData(state: any, action: any) {
      state.postSolarTariffData = action.payload;
    },
    setPostSolarTariffDataLoader(state: any, action: any) {
      state.postSolarTariffDataLoader = action.payload;
    },
    setPanelGroupData(state: any, action: any) {
      state.panelGroupsData = action.payload;
    },
    setPanelGroupTableLoader(state: any, action: any) {
      state.panelGropuTableLoader = action.payload;
    },
    setDesignConfigurationsLoader(state: any, action: any) {
      state.designConfigurationsLoader = action.payload;
    },
    setSolutionImages(state: any, action: any) {
      state.solutionImages = action.payload;
    },
    setSolutionImagesLoader(state: any, action: any) {
      state.solutionImagesLoader = action.payload;
    },
    setBatterySettings(state: any, action: any) {
      state.batterySettings = action.payload;
    },
    setBatterySettingsLoader(state: any, action: any) {
      state.batterySettingsLoader = action.payload;
    },
    setBatteryBidsLoader(state: any, action: any) {
      state.batteryBidsLoader = action.payload;
    },
    setUpdateBidsToggle(state: any, action: any) {
      state.updateBidsToggle = action.payload;
    },
    setBidsListLoader(state: any, action: any) {
      state.bidsListLoader = action.payload;
    },
    setSelectedBidsListLoader(state: any, action: any) {
      state.selectedBidsListLoader = action.payload;
    },
    setEnergyProfileFields(state: any, action: any) {
      state.energyProfileFields = action.payload;
    },
    setSystemData(state: any, action: any) {
      state.system_data = action.payload;
    },
    setSolutionAddersResponse(state: any, action: any) {
      state.solutionAddersResponse = action.payload;
    },
    setSolarAdderData(state: any, action: any) {
      state.solarAdders = action.payload;
    },
    setAddersLoader(state: any, action: any) {
      state.addersLoader = action.payload;
    },
    setPriorities(state: any, action: any) {
      state.priority = action.payload;
    },
    setImageGalleryBidsData(state: any, action: any) {
      state.imageGallery = action.payload;
    },
    setAdderAlertMessage(state: any, action: any) {
      state.adderAlertMessage = action.payload;
    },
    setBidDetails(state: any, action: any) {
      state.bidDetails = action.payload;
    },
    setDiscountReasons(state: any, action: any) {
      state.discountReasons = action.payload;
    },
    setmanagerList(state: any, action: any) {
      state.managerList = action.payload;
    },
    setBidDetailFinancing(state: any, action: any) {
      state.bidDetailFinancing = action.payload;
    },
    setUpdateBatteryBids(state: any, action: any) {
      state.batteryList = action.payload;
    },
    setLoanOptions(state: any, action: any) {
      state.loanOptions = action.payload;
    },

    setCartItems(state: any, action: any) {
      state.cartItems = action.payload;
    },
    setCartItemsLoader(state: any, action: any) {
      state.cartItemsLoader = action.payload;
    },
    setRankedBatteryBids(state: any, action: any) {
      state.rankedBatteryBids = action.payload;
    },
    setRankedBatteryBidsLoader(state: any, action: any) {
      state.rankedBatteryBidsLoader = action.payload;
    },
    setCustomerSelectedBid(state: any, action: any) {
      state.customerSelectedBid = action.payload;
    },
    setRankedSolarBids(state: any, action: any) {
      state.rankedSolarBids = action.payload;
    },
    setUserAgreement(state: any, action: any) {
      state.userAgreement = action.payload;
    },
    setTemplatesList(state: any, action: any) {
      state.templatesList = action.payload;
    },
    setCompletedDesignsList(state: any, action: any) {
      state.completedDesignsList = action.payload;
    },
    setSolutionErrorMessage(state: any, action: any) {
      state.createSolutionErrorMessage = action.payload;
    },
    setNewSolutionFlag(state: any, action: any) {
      state.newSolutionFlag = action.payload;
    },
    setCloseCreateSolutionPopup(state: any, action: any) {
      state.closeCreateSolutionPopup = action.payload;
    },
    setGenerateContract(state: any, action: any) {
      state.generateContract = action.payload;
    },
    setHandleSolution(state: any, action: any) {
      state.handleSolution = action.payload;
    },
    setLoansToogle(state: any, action: any) {
      state.loansToogle = action.payload;
    },
    setLoanInputs(state: any, action: any) {
      state.loan_inputs = action.payload;
    },
    setSystemPanelGroupData(state: any, action: any) {
      state.systemPanelGroupData = action.payload;
    },
    setLoansDropdownData(state: any, action: any) {
      state.loansDropdownData = action.payload;
    },
    setBidsCalculationStatus(state: any, action: any) {
      state.bidCalculationStatus = action.payload;
    },
    setPostSolarTariffValues(state: any, action: any) {
      state.postSolarTariffValues = action.payload;
    },
    setInflationRate(state: any, action: any) {
      state.inflation_rate = action.payload;
    },
    setselectPriceRankbids(state: any, action: any) {
      state.selectPriceRankbids = action.payload;
    },
    setPriceReductionData(state: any, action: any) {
      state.priceReductionData = action.payload;
    },
    setChangedSolutionToggle(state: any, action: any) {
      state.changedSolutionToggle = action.payload;
    },
    setDesignImages(state: any, action: any) {
      state.designImages = action.payload;
    },
    setProjetSolutionStatus(state:any,action:any){
      state.projectSolutionStatus = action.payload
    },
    setActualProjetSolutionStatus(state:any,action:any){
      state.actualProjectSolutionStatus = action.payload
    }
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  setSolutionStage,
  setSolutionsList,
  setSolutionListLoader,
  setSelectedSolution,
  setDesinersList,
  setRoofTypeList,
  setDesinRequirementData,
  setDesinRequirementDataLoader,
  setDesignerDropdownValues,
  setSystemFinancialInfo,
  setSystemFinancialInfoLoader,
  setConsumptionAdjustments,
  setSolarSystemAssumptions,
  setSolarSystemAssumptionsLoader,
  setPostSolarTariff,
  setPostSolarTariffData,
  setPostSolarTariffDataLoader,
  setPostSolarTariffValues,
  setPanelGroupData,
  setPanelGroupTableLoader,
  setDesignConfigurationsLoader,
  setSolutionImages,
  setSolutionImagesLoader,
  setBatterySettings,
  setBatterySettingsLoader,
  setBatteryBidsLoader,
  setUpdateBidsToggle,
  setEnergyProfileFields,
  setSystemData,
  setSolutionAddersResponse,
  setSolarAdderData,
  setAddersLoader,
  setBidsListLoader,
  setSelectedBidsListLoader,
  setPriorities,
  setImageGalleryBidsData,
  setAdderAlertMessage,
  setBidDetails,
  setmanagerList,
  setDiscountReasons,
  setBidDetailFinancing,
  setLoanOptions,
  setUpdateBatteryBids,
  setCartItems,
  setCartItemsLoader,
  setRankedBatteryBids,
  setRankedBatteryBidsLoader,
  setCustomerSelectedBid,
  setRankedSolarBids,
  setUserAgreement,
  setTemplatesList,
  setCompletedDesignsList,
  setSolutionErrorMessage,
  setNewSolutionFlag,
  setCloseCreateSolutionPopup,
  setGenerateContract,
  setHandleSolution,
  setLoansToogle,
  setLoanInputs,
  setSystemPanelGroupData,
  setLoansDropdownData,
  setBidsCalculationStatus,
  setInflationRate,
  setselectPriceRankbids,
  setPriceReductionData,
  setChangedSolutionToggle,
  setDesignImages,setProjetSolutionStatus,setActualProjetSolutionStatus
} = solutionsSlice.actions;


export const getSolutionData = (data: any): ThunkAction<void, any, unknown, Action> => 
   async (dispatch: any, getState: any) =>{
    axiosService('POST' , ENGINE_URL + `hubble/getSolution`,true, null, false, data)
    .then(response => {
      if(response.data.success) {
        let solutionsData = response.data.data;
        const {energy_profile, target_yearly_production,battery_settings, design_images} = solutionsData;
        dispatch(setDesignImages(design_images));
        dispatch(setSystemPanelGroupData(solutionsData.panel_groups));
        dispatch(setSystemData(solutionsData));
        dispatch(setHandleSolution(solutionsData.is_enabled));
        dispatch(setEnergyProfileFields({
          ...getState.energyProfileFields,
          avg_monthly_consumption: energy_profile?.avg_monthly_consumption?.toFixed(2),
          monthly_bill_adjustment:energy_profile?.monthly_bill_adjustment?.toFixed(2),
          monthly_kwh_adjustment:energy_profile?.monthly_kwh_adjustment?.toFixed(2),
          target_bill_offset:energy_profile?.target_bill_offset?.toFixed(2),
          avg_monthly_bill:energy_profile?.avg_monthly_bill?.toFixed(2),
          target_monthly_production:energy_profile?.target_monthly_production?.toFixed(2),
          target_yearly_production:target_yearly_production?.toFixed(2),     
        }));
        dispatch(
          setPostSolarTariffValues(energy_profile?.post_solar_tariff_id)
        );
        dispatch(setInflationRate(energy_profile?.inflation_rate))
        dispatch(setUpdateBatteryBids({
          ...getState.batteryList,
          batteryMinCapacity:battery_settings?.batteryMinCapacity,
          startTime:battery_settings?.startTime,
          stateOfCharge:battery_settings?.stateOfCharge,
          hourPowerOut:battery_settings?.hourPowerOut,
          endTime:battery_settings?.endTime,
          criticalLoad:battery_settings?.criticalLoad,
          dischargeMode:battery_settings?.dischargeMode
        }))
        dispatch(getAdders({
            "project_id": data?.project_id,
            "solution_id": response.data.data?.id
        }));
        dispatch(bidCalculateStatus(response.data.data?.id));
        getTraiffs(data?.project_id, solutionsData?.lse_id).then((response) => {
          let data : any = []
          if (response.data.success) {
            response.data.data?.map((item : any) =>
              data.push({
                tariff_id: item.tariff_id,
                name: item.tariff_name,
                utility_rate_id: item.id,
                value: item.tariff_name,
                masterTariffId: item.master_tariff_id,
              })
            );
            dispatch(setPostSolarTariff(data));
          }
        });
      }
      if(getState().solutions.newSolutionFlag === 1 || data.solution_id === null ){
        dispatch(getSolutionList({project_id:data.project_id}));
        let newSolutionSatus : any = 2;
        dispatch(setNewSolutionFlag(newSolutionSatus));
      }
    })
    .catch(error => {
    });
};

export const selectNewSolution = (data: any) => {
    return axiosService('POST' , ENGINE_URL + `hubble/getSolution`,true, null, false, data)
}

export const getSolutionList = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  const loader : any = true;
  dispatch(setSolutionListLoader(loader));
  return axiosService(
    "POST",
    ENGINE_URL + `hubble/getSolutionList?ignoreLoader=true`,
    true,
    null,
    false,
    data
  )
    .then((response) => {
      if (response?.data?.success) {
        const loader: any = false;
        dispatch(setSolutionListLoader(loader));
        let dataResponse: any = response?.data?.data;
        dispatch(setSolutionsList(dataResponse));
        dispatch(setSelectedSolution(dataResponse.find(item => item.in_view_selected === 1)));
      }
    })
    .catch((err: any) => {
      const loader: any = false;
      dispatch(setSolutionListLoader(loader));
    });
};
export const unHideSolutions = (project_id: any): ThunkAction<void, any, unknown, Action> => 
  async (dispatch: any, getState: any) => {
    return axiosService(
      "GET",
      ENGINE_URL + `hubble/unhideAllSolution/${project_id}?ignoreLoader=true`,
      true,
      null,
      false,
    ).then((response) => {
        if (response?.data?.success) {
          dispatch(getSolutionList({project_id:project_id}));
        }
      })
      .catch((err: any) => {
        showToast('ERROR',err.response.data.message);
      });
  };
//getSolutionOptionValues
export const getSolutionOptionValues =
  (): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    return axiosService(
      "GET",
      ENGINE_URL +
        `hubble/getSolutionOptionValues?ignoreLoader=true`,
      true,
      null,
      false
    ).then((response) => {
      if (response?.data?.success) {
        let dataResponse: any = response?.data?.data;
        dispatch(setDesinersList(dataResponse?.designer_list));
        dispatch(setRoofTypeList(dataResponse?.roof_type_list));
      }
    });
  };

//getDesignRequirement
export const getDesignRequirement =
  (solutionId: any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    const loader : any = true;
    dispatch(setDesinRequirementDataLoader(loader));
    return axiosService(
      "GET",
      ENGINE_URL +
        `hubble/getDesignRequirement/${solutionId}?ignoreLoader=true`,
      true,
      null,
      false
    ).then((response) => {
      if (response?.data?.success) {
            const loader: any = false;
            dispatch(setDesinRequirementDataLoader(loader));
        let dataResponse: any = response?.data?.data;
        dispatch(setDesinRequirementData(dataResponse));
        dispatch(
          setDesignerDropdownValues({...getState().solutions.designerDropdownValues,
            designer: dataResponse.designer_id,
            panelBox: dataResponse.panel_box_amp,
          })
        );
      }
    }).catch((err:any)=>{
          const loader: any = false;
          dispatch(setDesinRequirementDataLoader(loader));
    })
  };

  //updateDesignRequirement
  export const updateDesignRequirement =
    (data: any, key :any, value : any): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      return axiosService(
        "POST",
        ENGINE_URL + `hubble/updateDesignRequirement`,
        true,
        null,
        false,
        data
      ).then((response) => {
        if (response?.data?.success) {
          dispatch(
            setDesignerDropdownValues({
              ...getState().solutions.designerDropdownValues,
              [key]: value,
            })
          );
          showToast('SUCCESS',response.data.message);
        }
      }).catch((err:any) => showToast('ERROR',err.response.data.message));
    };

//getSolutionEnergyData
export const getSolutionEnergyData =
  (solutionId: any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    const loader : any = true;
    dispatch(setSystemFinancialInfoLoader(loader));
    return axiosService(
      "GET",
      ENGINE_URL +
        `hubble/getSolutionEnergyData/${solutionId}?ignoreLoader=true`,
      true,
      null,
      false
    ).then((response) => {
      if (response?.data?.success) {
        const loader: any = false;
        dispatch(setSystemFinancialInfoLoader(loader));
        let dataResponse: any = response?.data?.data;
        dispatch(setSystemFinancialInfo(dataResponse));
        dispatch(
          setConsumptionAdjustments({
            ...getState().solutions.consumptionAdjustments,
            adjustmentType: dataResponse.adjustment_key,
            adjustmentValue: dataResponse[dataResponse.adjustment_key],
          })
        );
        if(dataResponse.hasOwnProperty('bids_created')){
          dispatch(
            setUpdateBidsToggle({
              ...getState().solutions.updateBidsToggle,
              bids_created:dataResponse.bids_created,
            })
          );
        }
      }
    }).catch((err:any)=>{
      const loader: any = false;
      dispatch(setSystemFinancialInfoLoader(loader));
    })
  };

  //calculateAdjustments
  export const calculateAdjustment =
    (data: any): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      const loader: any = true;
      dispatch(setSystemFinancialInfoLoader(loader));
      return axiosService(
        "POST",
        ENGINE_URL + `hubble/calculateAdjustments`,
        true,
        null,
        false,
        data
      )
        .then((response) => {
          if (response.data) {
            const loader: any = false;
            dispatch(setSystemFinancialInfoLoader(loader));
            let dataResponse: any = response.data;
            dispatch(setSystemFinancialInfo(dataResponse));
            dispatch(
              setConsumptionAdjustments({
                ...getState().solutions.consumptionAdjustments,
                adjustmentType: dataResponse.adjustment_key,
                adjustmentValue: dataResponse[dataResponse.adjustment_key],
              })
            );
      if (getState().solutions.updateBidsToggle.bids_created) {
        dispatch(
          setUpdateBidsToggle({
            ...getState().solutions.updateBidsToggle,
            changeInAdjustmentOrPanelGroups: true,
            text: "You have updated the consumption. To create new bids, please click on the Create Bids CTA.",
          })
        );
      }
            showToast("SUCCESS", 'Adjustments updated successfully');
          }
        })
        .catch((err: any) => {showToast("ERROR", err.response.data.message);
          const loader: any = false;
          dispatch(setSystemFinancialInfoLoader(loader));
        });
    };

  //getPostSolarTariffOptions
  export const getPostSolarTariffOptions =
    (projectId: any): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      return axiosService(
        "GET",
        ENGINE_URL +
          `hubble/getPostSolarTariffOptions/${projectId}?ignoreLoader=true`,
        true,
        null,
        false
      ).then((response) => {
        if (response?.data?.success) {
          let dataResponse: any = response?.data?.data;
          dispatch(setPostSolarTariff(dataResponse));
        }
      });
    };

  //getPostSolarTariffData 
export const getPostSolarTariffData =
  (projectId: any, solutionId:any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    const loader : any = true;
    dispatch(setPostSolarTariffDataLoader(loader));
    return axiosService(
      "GET",
      ENGINE_URL + `hubble/getPostSolarTariff/${projectId}/${solutionId}?ignoreLoader=true`,
      true,
      null,
      false
    ).then((response) => {
      if (response?.data?.success) {
        const loader: any = false;
        dispatch(setPostSolarTariffDataLoader(loader));
        let dataResponse: any = response?.data?.data;
        dispatch(setPostSolarTariffData(dataResponse));
        dispatch(
          setPostSolarTariffValues({
            ...getState().solutions.postSolarTariffValues,
            tariffId: dataResponse.post_solar_tariff_id,
            rate: dataResponse.inflation_rate,
          })
        );
      }
    }).catch((err:any)=>{
      const loader: any = false;
      dispatch(setPostSolarTariffDataLoader(loader));
    });
  };

  //updatePostSolarTariff
  export const updatePostSolarTariff =
    (
      projectId: any,
      solutionId: any, data:any
    ): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      const loader: any = true;
      dispatch(setPostSolarTariffDataLoader(loader));
      return axiosService(
        "POST",
        ENGINE_URL +
          `hubble/updatePostSolarTariff/${projectId}/${solutionId}?ignoreLoader=true`,
        true,
        null,
        false, data
      ).then((response) => {
        if (response?.data?.success) {
          const loader: any = false;
          dispatch(setPostSolarTariffDataLoader(loader));
          let dataResponse: any = response?.data?.data;
          dispatch(setPostSolarTariffData(dataResponse));
          dispatch(
            setPostSolarTariffValues({
              ...getState().solutions.postSolarTariffValues,
              tariffId: dataResponse.post_solar_tariff_id,
              rate: dataResponse.inflation_rate,
            })
          );
        }
      }).catch((err:any) => {
        const loader: any = false;
        dispatch(setPostSolarTariffDataLoader(loader));
      });
    };


  //getPanelGroups
  export const getPanelGroups =
    ( data: any ): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      const loader : any = true;
      dispatch(setPanelGroupTableLoader(loader));
      dispatch(setDesignConfigurationsLoader(loader));
      return axiosService(
        "POST",
        ENGINE_URL + `hubble/getPanelGroups?ignoreLoader=true`,
        true,
        null,
        false,
        data
      )
        .then((response) => {
          if (response?.data?.success) {
            const loader: any = false;
            dispatch(setPanelGroupTableLoader(loader));
            dispatch(setDesignConfigurationsLoader(loader));
            let dataResponse: any = response?.data?.data;
            dispatch(
              setPanelGroupData({
                ...getState().solutions.panelGroupsData,
                metadata: dataResponse.metadata,
                panel_groups: dataResponse.panel_groups,
              })
            );
          }
        })
        .catch((err: any) => {
          const loader: any = false;
          dispatch(setPanelGroupTableLoader(loader));
          dispatch(setDesignConfigurationsLoader(loader));
        });
    };

//uploadHourlyProduction
export const uploadHourlyProduction = async (data: any) => {
  return axiosService(
    "POST",
    ENGINE_URL + `hubble/uploadHourlyProductionFile`,
    true,
    null,
    true,
    data
  );
};

//upsertPanelGroup
export const upsertPanelGroup =
  (data: any, callType:any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    if(callType === 'savePanel'){
      const loader: any = true;
      dispatch(setPanelGroupTableLoader(loader));
      dispatch(setDesignConfigurationsLoader(loader));
      if(getState().solutions.updateBidsToggle.bids_created){
        dispatch(
          setUpdateBidsToggle({
            ...getState().solutions.updateBidsToggle,
            changeInAdjustmentOrPanelGroups: true,
            text: "You have updated the Panel Group(s). To create new bids, please click on the Create Bids CTA.",
          })
        );
      }
    }else{
      const loader: any = true;
      dispatch(setPanelGroupTableLoader(loader));
    }
    return axiosService(
      "POST",
      ENGINE_URL + `hubble/upsertPanelGroup?ignoreLoader=true`,
      true,
      null,
      false,
      data
    )
      .then((response: any) => {
        if (response.data.success) {
          let dataResponse: any = response.data.data;
          if (callType === "addPanel") {
            const loader: any = false;
            dispatch(setPanelGroupTableLoader(loader));
            dispatch(
              setPanelGroupData({
                ...getState().solutions.panelGroupsData,
                panel_groups: dataResponse,
              })
            );
            showToast("SUCCESS", "Panel Group added successfully");
          } else if (callType === "deletepanel") {
            const loader: any = false;
            dispatch(setPanelGroupTableLoader(loader));
            dispatch(
              setPanelGroupData({
                ...getState().solutions.panelGroupsData,
                panel_groups: dataResponse,
              })
            );
            showToast("SUCCESS", "Panel Group deleted successfully");
          }else if(callType === 'updatePanelSizeNfloors'){
            const loader: any = false;
            dispatch(setPanelGroupTableLoader(loader));
            showToast("SUCCESS", "Base Panel Size or Building Floors updated successfully");
          } else {
            if(getState().solutions.projectSolutionStatus?.designStatus === false){
              dispatch(getProjectSolutionStatus(data.solution_id));
            }
            const loader: any = false;
            dispatch(setPanelGroupTableLoader(loader));
            dispatch(setDesignConfigurationsLoader(loader));
            dispatch(
              setPanelGroupData({
                ...getState().solutions.panelGroupsData,
                panel_groups: dataResponse,
                metadata: {...getState().solutions.panelGroupsData.metadata,
                  base_panel_size: data.base_panel_size,
                  building_flores: data.building_flores
                }
              })
            );
            showToast("SUCCESS", "Panel Group(s) saved successfully");
          }
        }
      })
      .catch((error: any) => {
        const loader: any = false;
        dispatch(setPanelGroupTableLoader(loader));
        dispatch(setDesignConfigurationsLoader(loader));
        showToast("ERROR", error.response.data.message);
      });
  };

//getSolutionImages
export const getSolutionImages =
  (solutionId: any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    const loader : any = true;
    dispatch(setSolutionImagesLoader(loader));
    return axiosService(
      "GET",
      ENGINE_URL + `hubble/getSolutionImages/${solutionId}?ignoreLoader=true`,
      true,
      null,
      false
    ).then((response) => {
      if (response?.data?.success) {
        const loader: any = false;
        dispatch(setSolutionImagesLoader(loader));
        let dataResponse: any = response?.data?.data;
        dispatch(setSolutionImages(dataResponse));
      }
    }).catch((err:any) => {
      const loader: any = false;
      dispatch(setSolutionImagesLoader(loader));
    })
  };

  //updateDesignImages
  export const uploadDesignFile =
    (solutionId:any,data: any): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      return axiosService(
        "POST",
        ENGINE_URL + `hubble/uploadDesignFile/${solutionId}`,
        true,
        null,
        true,
        data
      ).then((response) => {
        if (response?.data?.success) {
          if(!getState().solutions.projectSolutionStatus.designStatus){
            dispatch(getProjectSolutionStatus(solutionId));
          }
          dispatch(getSolutionImages(solutionId));
        }
      });
    };

//getSolarSystemAssumptions
export const getSolarSystemAssumptions =  (solutionId:any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    const loader : any = true;
    dispatch(setSolarSystemAssumptionsLoader(loader));
    return axiosService(
      "GET",
      ENGINE_URL + `hubble/getSolarSystemAssumptions/${solutionId}?ignoreLoader=true`,
      true,
      null,
      false
    ).then((response) => {
      if (response?.data?.success) {
        const loader: any = false;
        dispatch(setSolarSystemAssumptionsLoader(loader));
        let dataResponse: any = response?.data?.data;
        dispatch(
          setSolarSystemAssumptions({
            ...getState().solutions.solarSystemAssumptions,
            "solar_system_size": dataResponse.solar_system_size,
            "target_yearly_production": dataResponse.target_yearly_production
          })
        );
      }
    }).catch((err:any)=>{
      const loader: any = false;
      dispatch(setSolarSystemAssumptionsLoader(loader));
    });
  };

  //updateSolarSystemAssumptions
  export const updateSolarSystemAssumptions =  (data:any): ThunkAction<void, any, unknown, Action> =>
    async (dispatch: any, getState: any) => {
      const loader : any = true;
      dispatch(setSolarSystemAssumptionsLoader(loader));
      return axiosService(
        "POST",
        ENGINE_URL + `hubble/updateSolarSystemAssumptions?ignoreLoader=true`,
        true,
        null,
        false,data
      ).then((response) => {
        if (response?.data?.success) {
          const loader: any = false;
          dispatch(setSolarSystemAssumptionsLoader(loader));
          showToast('SUCCESS','Solar Assumptions updated successfully')
        }
      }).catch((err:any)=>{
        showToast('ERROR',err.response.data.message);
        const loader: any = false;
        dispatch(setSolarSystemAssumptionsLoader(loader));
      });
    };
//getBatterySetting
export const getBatterySetting = (solutionId: any): ThunkAction<void, any, unknown, Action> =>
async (dispatch: any, getState: any) => {
  const loader : any = true;
  dispatch(setBatterySettingsLoader(loader));
  return axiosService(
    "GET",
    ENGINE_URL + `hubble/getBatterySetting/${solutionId}?ignoreLoader=true`,
    true,
    null,
    false
  ).then((response) => {
    if (response?.data?.success) {
      const loader: any = false;
      dispatch(setBatterySettingsLoader(loader));
      let dataResponse: any = response?.data?.data;
      dispatch(setBatterySettings(dataResponse));
    }
  }).catch((err:any) => {
    const loader: any = false;
    dispatch(setBatterySettingsLoader(loader));
  })
};

//getSolutionAdders
export const getAdders = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  const loader : any =true;
  dispatch(setAddersLoader(loader));
  axiosService(
    "GET",
    ENGINE_URL +
      `hubble/getSolutionAdders/${data?.project_id}/${data?.solution_id}?ignoreLoader=true`,
    true
  )
    .then((response) => {
      if (response.data.success) {
        const loader: any = false;
        dispatch(setAddersLoader(loader));
        dispatch(setSolutionAddersResponse(response.data.data));
        dispatch(getBidgenErrorList(data?.solution_id))
      }
    })
    .catch((err: any) => {
      const loader: any = false;
      dispatch(setAddersLoader(loader));
    });
}


//saveAddersData
export const saveAdders = async (data: any,payload: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/saveAddersData/${data?.project_id}/${data?.solution_id}`, true, null, false , payload)
}

//createBids
export const CreateBids =
  (data: any): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
    axiosService(
      "POST",
      ENGINE_URL + `hubble/createBids/${data?.project_id}/${data?.solution_id}`,
      true,
      null,
      false
    ).then((response) => {
      if (response.data.success) {
          dispatch(setProjetSolutionStatus({
            ...getState().solutions.projectSolutionStatus, bidsStatus:true
          }));
          dispatch(setActualProjetSolutionStatus({
            ...getState().solutions.actualProjectSolutionStatus, bidsStatus:true
          }));
        dispatch(getBidgenErrorList(data?.solution_id))
        dispatch(
          setUpdateBidsToggle({
            ...getState().solutions.updateBidsToggle,
            changeInAdjustmentOrPanelGroups: false,
            text:''
          })
        ); 
        showToast('SUCCESS','Bids created successfully')
      }
    }).catch((err:any) => {
      showToast('ERROR',err.response.data.message);
    });
  };

  //getBidgenErrorList
  export const getBidgenErrorList = (solutionId: any): ThunkAction<void, any, unknown, Action> => 
    async (dispatch: any, getState: any) =>{
      axiosService('GET' , ENGINE_URL + `hubble/getBidgenErrorList/${solutionId}?ignoreLoader=true`, true, null, false ).then(response =>{
        if(response.data.success){
          dispatch(setAdderAlertMessage(response.data.data));
        }
      })
    }

export const calculateAdjustments = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/calculateAdjustments` , true , null , false, data).then(res => {
    if (res.status === 200) {
      const {avg_monthly_consumption,monthly_bill_adjustment , monthly_kwh_adjustment, target_bill_offset,
        avg_monthly_bill, target_monthly_production, target_yearly_production} = res.data;
      let energyProfileFields: any = {
        avg_monthly_consumption: avg_monthly_consumption?.toFixed(2),
        monthly_bill_adjustment: monthly_bill_adjustment?.toFixed(2),
        monthly_kwh_adjustment:  monthly_kwh_adjustment?.toFixed(2),
        target_bill_offset: target_bill_offset?.toFixed(2),
        avg_monthly_bill: avg_monthly_bill?.toFixed(2),
        target_monthly_production: target_monthly_production?.toFixed(2),
        target_yearly_production: target_yearly_production?.toFixed(2),
    }
      dispatch(setEnergyProfileFields(energyProfileFields));
      dispatch(setSystemPanelGroupData(res.data.panel_groups));

    }
  })
}

export const getBidsFilter = (projectId: any, solutionId: any, type: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('GET' , ENGINE_URL + `hubble/getBidFilters/${projectId}/${solutionId}?ignoreLoader=true`, true, null, false ).then(response =>{
    if(response.data.success){
      dispatch(
        setImageGalleryBidsData({
          ...getState().solutions.imageGallery,
          bidsFilters:response.data.data})
      );
    }
  })
}

export const getBidListData = (projectId: any,solutionId: any, data: any, updatedBidsList?:any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  const loader : any = true;
  dispatch(setBidsListLoader(loader));
     axiosService(
       "POST",
       ENGINE_URL + `hubble/getBids/${projectId}/${solutionId}?ignoreLoader=true`,
       true,
       null,
       false,
       data
     ).then((response) => {
       if (response.data.success) {
          dispatch(
            setImageGalleryBidsData({
              ...getState().solutions.imageGallery,
              bidsList: response.data.data,
            })
          );
          if(updatedBidsList){
            dispatch(getSelectedBids(projectId,solutionId,{
              "bids_type" : data.bids_type,
              "is_selected" : 1,
            }))
          }
          const loader: any = false;
          dispatch(setBidsListLoader(loader));
       }
     }).catch((err:any) => {
        const loader: any = false;
        dispatch(setBidsListLoader(loader));
        showToast("ERROR",err.response.data.message)
     });
}


export const getSelectedBids =
  (
    projectId: any,
    solutionId: any,
    data: any
  ): ThunkAction<void, any, unknown, Action> =>
  async (dispatch: any, getState: any) => {
      const loader: any = true;
      dispatch(setSelectedBidsListLoader(loader));
    axiosService(
      "POST",
      ENGINE_URL + `hubble/getBids/${projectId}/${solutionId}?ignoreLoader=true`,
      true,
      null,
      false,data
    ).then((response) => {
      if (response.data.success) {
        const loader: any = false;
        dispatch(setSelectedBidsListLoader(loader));
        dispatch(
          setImageGalleryBidsData({
            ...getState().solutions.imageGallery,
            selectedBids: response.data.data.bids,
          })
        );  
      }
    }).catch((err:any) => {
        const loader: any = false;
        dispatch(setSelectedBidsListLoader(loader));
        showToast("ERROR",err.response.data.message)
     });;
  };

//calculateBids/battery

export const calculateSolarBatteryBids = async (data: any) => {
  return axiosService('POST' , SQS_JOB + `api/jobs/trigger_jobs`, false, null, false, data );
  }

  export const calculateBatteryBids = async (data: any) => {
    return axiosService('POST' , SQS_JOB + `api/jobs/trigger_jobs`, false, null, false, data );
  }

  export const calculateSolarBids = async (data: any) => {
    return axiosService('POST' , SQS_JOB + `api/jobs/trigger_jobs`, false, null, false, data );
  }

export const getproductListData = async (projectId: any,solutionId: any, data: any) => {
return axiosService('POST' , ENGINE_URL + `hubble/getBids/${projectId}/${solutionId}?ignoreLoader=true`, true, null, false, data );
}

export const updateproductListRank = async (projectId: any,solutionId: any, data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/updateBidRank/${projectId}/${solutionId}?ignoreLoader=true`, true, null, false, data );
}


export const updateSelectedBid = async (projectId: any,solutionId: any, data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/updateSelectedFlag/${projectId}/${solutionId}`, true, null, false, data )
}
export const getBidDetails = (id: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/getBidData/${id}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setBidDetails(response.data.data));
    }
  })
}

export const getDiscontReasons =  () : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('GET' , ENGINE_URL + `hubble/getDiscountReasons`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setDiscountReasons(response.data?.data));
    }
  })
}

export const getManagerList =  () : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('GET' , ENGINE_URL + `hubble/getManagerList`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setmanagerList(response.data?.data));
    }
  })
}

export const addBidToCart = (data: any,projectType?:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST' , ENGINE_URL + `hubble/addToCart`, true, null, false, data ).then(response =>{
    if(response.data.success){
      dispatch(setCustomerSelectedBid(response.data.data.bid_id))
      // dispatch(getCartItems({project_id:data.project_id},projectType));
      //dispatch(bidCalculateStatus(response.data.data.solution_id));
      showToast("SUCCESS", response.data.message);
    }
  }).catch((err:any)=>showToast('ERROR',err.response.data.message));
}

export const updateBidInCart = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/addToCart`, true, null, false, data );
}


export const removeBidFromCart = (data: any,checkoutToggle?:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST' , ENGINE_URL + `hubble/removeFromCart`, true, null, false, {bid_id:data.bid_id} ).then(response =>{
    if(response.data.success){
      if(checkoutToggle){
        dispatch(getCartItems({project_id:data.project_id}));
      }
      showToast("SUCCESS", response.data.message);
    }
  }).catch((err:any)=>showToast('ERROR',err.response.data.message))
}

//updateBidData/141860
export const updateBidData = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST' , ENGINE_URL + `hubble/updateBidData/${data?.bid_id}`, true, null, false, data ).then(response =>{
    if(response.data.success){
      dispatch(getBidDetails(data.bid_id));
      showToast("SUCCESS", response.data.message);
    }
  })
}
export const UpdateBatteryBids = async ( data: any) => {
  return axiosService(
    "POST",
    ENGINE_URL + `hubble/updateBatterySetting?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
}

export const getLoanOptions = (data: any): ThunkAction<void, any, unknown, Action> => async (dispatch : any, getState: any) => {
  axiosService('GET' , ENGINE_URL + `hubble/getLoanOptions/${data.projectId}/${data.solutionId}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setLoanOptions(response.data?.data));
    }
  })
}

export const saveLoanOptions = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST' , ENGINE_URL + `hubble/saveLoanOptions`, true, null, false, data ).then(response =>{
    if(response.data.success){
      dispatch(setLoanOptions(response.data?.data));
      showToast("SUCCESS", 'Loan Options saved successfully');
    }
  })
}

export const calculateBids = (projectId: any, solutionId : any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST' , ENGINE_URL + `hubble/calculateBids/${projectId}/${solutionId}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setLoanOptions(response.data?.data));
      dispatch(bidCalculateStatus(solutionId));
      showToast("SUCCESS", 'Bids Calculated successfully');
    }
  }).catch((err)=> {
    showToast('ERROR',err.response?.data?.message)
  });
}

export const getCartItems = (data: any, projectType?:any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  const loader : any = true;
  dispatch(setCartItemsLoader(loader));
  axiosService('POST' , ENGINE_URL + `hubble/getCartItems?ignoreLoader=true`, true, null, false, data ).then((response:any)=>{
    if(response.data.success){
      const loader : any = false;
      dispatch(setCartItemsLoader(loader));
      dispatch(setCartItems(response.data.data));
      if(projectType !== 'Battery'){
        (response.data.data).forEach((item:any)=>{
          if(item.bid_type === "solar"){
            let obj : any = item.finance_type === 'loan' ? {
              "finance_type": 'loan',
              "financier_id": item.financier_data?.financier_id ?? null,
              "financier_loan_option_id": item.financier_data?.financier_loan_option_id ?? null,
              "financier_loan_configuration_id": item.financier_data?.financier_loan_configuration_id ?? null,
              "down_payment":item.extra_data.payment.LoanOption?.downPayment ?? null
            } : {
              financier_id: null,
              financier_loan_option_id: null,
              financier_loan_configuration_id: null,
              down_payment: null,
              "finance_type": "cash",
            }
            dispatch(setLoanInputs(obj))
          }
        }); 
      }else{
        (response.data.data)?.forEach((item:any)=>{
          if(item.bid_type === "battery"){
            let obj : any = {
              "finance_type": item.finance_type,
              "financier_id": item.financier_data.financier_id,
              "financier_loan_option_id": item.financier_data.financier_loan_option_id,
              "financier_loan_configuration_id": item.financier_data.financier_loan_configuration_id,
              "down_payment":item.extra_data.payment.LoanOption.downPayment
            }
            dispatch(setLoanInputs(obj))
          }
        }); 
      }
    }
  }).catch((err:any)=>{
    showToast('ERROR',err.response.data.message);
    const loader : any = false;
    dispatch(setCartItemsLoader(loader));
  })
}

export const getRankedBatteryBids = (projectId: any,solutionId: any,data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  const loader : any = true;
  dispatch(setRankedBatteryBidsLoader(loader));
  axiosService('POST' , ENGINE_URL + `hubble/getBids/${projectId}/${solutionId}?ignoreLoader=true`, true, null, false, data ).then((response:any)=>{
    if(response.data.success){
      const loader : any = false;
      dispatch(setRankedBatteryBidsLoader(loader));
      if(getState().projectList.projectDetails?.partner_project_type === 'Solar+Battery'){
        dispatch(setRankedBatteryBids(response.data.data?.bids));
      }else{
        let updatedData : any = response.data.data?.bids?.map((item:any)=>({
          name: `Bid ${item.bid_rank}`,
          id: item.id,
          client_selected:item.client_selected,
          financing_options: JSON.parse(item.financiers_mapping),
          maxDownPayment: item.maxDownPayment
        }));
        dispatch(setRankedBatteryBids(updatedData));
      }
      let selectedBattery = response.data.data?.bids.find((item: any) => item.client_selected === 1);
      dispatch(setCustomerSelectedBid(selectedBattery?.id));
    }
  }).catch((err:any) => {
    showToast('ERROR',err.response.data.message);
    const loader : any = false;
    dispatch(setRankedBatteryBidsLoader(loader));
  })
}

export const getRankedSolarBids = (projectId: any,solutionId: any,data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/getBids/${projectId}/${solutionId}?ignoreLoader=true`, true, null, false, data ).then((response:any)=>{
    if(response.data.success){
      const bidInCart : any = response.data.data?.bids?.find((item:any) => item.client_selected === 1).id;
      let updatedData : any = response.data.data?.bids?.map((item:any)=>({
        name: `Bid ${item.bid_rank}`,
        id: item.id,
        client_selected:item.client_selected,
        financing_options: JSON.parse(item.financiers_mapping),
        maxDownPayment: item.maxDownPayment
      }))
      if(getState().projectList.projectDetails?.partner_project_type === 'Solar+Battery'){
        dispatch(getRankedBatteryBids(projectId, solutionId, {
          "bids_type": "battery",
          "parent_bid_id": bidInCart,
          "page": 1,
          "limit": 10,
          "ranked_bids": 1
        }));
      }
      dispatch(setRankedSolarBids(updatedData))
    }
  })
}

export const updateLoan = (data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/calculatePayment`, true, null, false,data).then(response =>{
    if(response.data.success){
      dispatch(setCartItems(response.data.data))
    }
  }).catch((err:any) => {
    showToast('ERROR',`${err.response.data.message === "Financing is not available for the total contract price" ? "Financing is not available for the total contract price, as it exceeds the permitted limits." : err.response.data.message}`);
  })
}


export const getUserAgreement = (id:any) : ThunkAction<void, any, unknown, Action> => async (dispatch : any, getState : any) => {
  axiosService('GET', ENGINE_URL + `hubble/getUserAgreement/${id}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setUserAgreement(response.data.data));
    }
  })
}


export const getTemplates = (id:any) : ThunkAction<void, any, unknown, Action> => async (dispatch : any, getState : any) => {
  axiosService('GET', ENGINE_URL + `hubble/project/docusign/get-project-templates/${id}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setTemplatesList(response.data.data));
    }
  })
}


export const saveUserAgreement = (data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/saveUserAgreement`, true, null, false,data).then(response =>{
    if(response.data.success){
      dispatch(getProjectStatus(data.project_id,true));
      showToast('SUCCESS', response.data.message);
    }
  }).catch((err)=> dispatch(showToast('ERROR',err.response?.data?.message)));
}

export const getCompletedDesigns = (data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/getCompletedDesigns`, true, null, false,data).then(response =>{
    if(response.data.success){
      dispatch(setCompletedDesignsList(response.data.data))
    }
  })
}

export const renameSolution = (data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/renameSolution`, true, null, false,data).then(response =>{
    if(response.data.success){
      let newSolutionSatus : any = 2;
      dispatch(setNewSolutionFlag(newSolutionSatus));
      dispatch(getSolutionList({project_id:data.project_id}));
      dispatch(setSolutionErrorMessage(undefined));
      showToast('SUCCESS', 'Solution Renamed Successfully')
    }
  }).catch((err)=> {
    dispatch(setSolutionErrorMessage(err.response?.data?.message));
    showToast('ERROR',err.response?.data?.message);
  });
}


export const updateSolution = (data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/updateSolution`, true, null, false, data).then(response =>{
    if(response.data.success){
      showToast('SUCCESS', 'Solution Updated Successfully')
    }
  }).catch((err)=> dispatch(showToast('ERROR',err.response?.data?.message)));
}


export const createSolution1 = (data: any) : ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/createSolution`, true, null, false,data).then(response =>{
    if(response.data.success){
      let newSolutionSatus : any = 1;
      dispatch(getSolutionData({ project_id: data.project_id, solution_id: response.data.data.id }));
      dispatch(setNewSolutionFlag(newSolutionSatus));
      dispatch(setSolutionErrorMessage(undefined));
      dispatch(setCloseCreateSolutionPopup(newSolutionSatus));
    }
  }).catch((err)=> dispatch(setSolutionErrorMessage(err.response?.data?.message)));
}
export const createSolution = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/createSolution`, true, null, false,data);
}


export const updateSolutionStatus = (data:any,projectID:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/updateSolutionStatus`, true, null, false,data).then(response =>{
    if(response.data.success){
      dispatch(getSolutionList({project_id:projectID}));
      // const { solutionsList } = getState().solutions;
      // const updatedList = solutionsList.map((solution: any) => 
      //   solution.id === data.solution_id 
      //     ? { ...solution, is_enabled: response.data.data.is_enabled } 
      //     : solution
      // );
      // const selectedSolutionToEnable : any = updatedList.find((eachsolution:any)=>eachsolution.id === data.solution_id);
      // dispatch(setSelectedSolution(selectedSolutionToEnable));
      // dispatch(setSolutionsList(updatedList));
    }}).catch((err)=> {
      showToast('ERROR',err.response?.data?.message)
    });
}

export const updateBidExpiry = (data:any) =>{
  return axiosService('POST' , ENGINE_URL + `hubble/updateBidExpireDate`, true, null, false,data);
}

//hubble/bidCalculateStatus/869

export const bidCalculateStatus = (solution_id:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('GET' , ENGINE_URL + `hubble/bidCalculateStatus/${solution_id}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setBidsCalculationStatus(response.data.data))
     }});
}

export const checkBidStatus = (solution_id: any) => {
  return axiosService('GET' , ENGINE_URL + `hubble/bidCalculateStatus/${solution_id}`, true, null, false);
}
export const UploadPriceReduction = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/uploadBidPriceRequestFiles`,true, null, true, data)
}

// hubble/createBidPriceReductionRequest

export const createBidPriceReductionRequest = (data:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/createBidPriceAdjustmentRequest`, true, null, false,data).then(response =>{
    if(response.data.success){
      dispatch(setPriceReductionData(response?.data?.data));
    }}).catch((err)=> {
      showToast('ERROR',err.response?.data?.message)
    });
}

export const getBidPriceReductionRequest = (data:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) =>{
  axiosService('POST' , ENGINE_URL + `hubble/getBidPriceAdjustmentRequest`, true, null, false,data).then(response =>{
    if(response.data.success){
      dispatch(setPriceReductionData(response?.data?.data));
    }}).catch((err)=> {
      showToast('ERROR',err.response?.data?.message)
    });
}

//responedReductionRequest

export const responedReductionRequest = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/responedAdjustmentRequest`,true, null, false, data)
}
export const getIncentiveDiscount = (data: any) => {
  return axiosService(
    "GET",
    ENGINE_URL + `hubble/getPartnerDiscount/${data}?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
}
export const getIncentiveInformation = (data: any) => {
  return axiosService(
    "GET",
    ENGINE_URL +
      `hubble/getSolutionIncentives/${data}?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
}
export const refreshIncentive = (data: any) => {
  return axiosService(
    "GET",
    ENGINE_URL + `hubble/refreshSolutionIncentives/${data}?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
}
export const updateIncentive = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/updateSolutionIncentives` ,true, null, false,data)
}
export const setAsDefaultSolution = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/updateDefaultSolution` ,true, null, false,data)
}
export const hideSolution = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/hideSolution` ,true, null, false,data)
}
export const cloneSolution = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/cloneSolution` ,true, null, false,data)
}
export const editName = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/renameSolution` ,true, null, false,data)
}

export const getBidHistoryLogs = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/getBidHistoryLogs` ,true, null, false,data)
}

export const getBidNotes = (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/getBidNotes` ,true, null, false,data)
}

export const updateBidNotes = (data:any) => {
  return axiosService('POST', ENGINE_URL + `hubble/updateBidNotes`,true,null,false,data)
}

export const getBidExpiryDate = (data:any) => {
  return axiosService('POST', ENGINE_URL + `hubble/getBidExpiryDate?ignoreLoader=true`,true,null,false,data);
}

export const updateBatteryQty = (data:any) => {
  return axiosService('POST', ENGINE_URL + `hubble/updateBatteryQty?ignoreLoader=true`,true,null,false,data);
}

export const refreshLoanOptions =(data: any): ThunkAction<void, any, unknown, Action> => async (dispatch : any, getState: any) => {
  axiosService('GET' , ENGINE_URL + `hubble/refreshLoanOptions/${data.projectId}/${data.solutionId}`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setLoanOptions(response.data?.data));
      showToast('SUCCESS','Loans Options refreshed')
    }
  }).catch((err:any) => showToast('ERROR',err.response.data.message));
}

export const getProjectSolutionStatus = (solutionId: any): ThunkAction<void, any, unknown, Action> => async (dispatch : any, getState: any) => {
  axiosService('GET' , ENGINE_URL + `hubble/project/getProjectSolutionStatus/${solutionId}?ignoreLoader=true`, true, null, false).then(response =>{
    if(response.data.success){
      dispatch(setProjetSolutionStatus(response.data.data));
      dispatch(setActualProjetSolutionStatus(response.data.data));
    }
  }).catch((err:any) => showToast('ERROR',err.response.data.message));
}

export const updatePartnerDiscount = (data:any) => {
  return axiosService('POST', ENGINE_URL + `hubble/updatePartnerDiscount?ignoreLoader=true`,true,null,false,data);
}
export default solutionsSlice.reducer;